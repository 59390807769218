import React from "react";
import { PrismCode } from "../../common/PrismCode.js";


const ButtonCodeCSS = `body {
    font-family: "Lato", sans-serif;
    font-size: 1rem;
}

h1 {
    font-family: "Oswald", sans-serif;
    padding-top: 1.875rem;
    font-size: 1.875em;
}

h2 {
    font-family: "Oswald", sans-serif;
    font-size: 1.875em;
    padding-top: 1.875em;
    padding-bottom: 0.5em;
}

h3 {
    font-size: 1.3125rem;
    font-weight: 700;
    padding-top: 1.3125rem;
    padding-bottom: 0.5em;
}

h4 {
    font-size: 1.125rem;
    padding-bottom: 0.5em;
    font-weight: 700;
}

h5 {
    font-style: bold;
    font-weight: 700;
    font-size: 1rem;
}

h6 {
    font-weight: 900;
    font-size: 0.8rem;
}`;

const TypographyPage = () => (
	<div>
		<h1>Typography</h1>
		<h1>This is Oswald, our digital typeface equivalent to Futura.</h1>
		<p>
			{" "}
			Oswald is the primary brand typeface for LATICRETE<sup>&reg;</sup> digital mediums. An open source
			digital equivalent to the LATICRETE print typeface, Futura, this
			typeface is hosted by Google for easy inclusion in websites and web applications.
		</p>
		<h3> Lato is our supporting font</h3>
		<p>
			Lato is our workhorse typeface, chosen to support Oswald in more
			functional moments. Plain, legible, easy-to-read, this sans-serif balances
			the ‘Alternative Gothic’ style of Oswald.
		</p>
		<h4>Type Scale</h4>
		<p>
			We use Oswald Medium for headlines one and two. We use Lato for headlines
			three, four, five, and for body copy, buttons, and meta styling.
		</p>
		<h4>Relative Font Size</h4>
		<p>
			We use relative font sizes whenever possible by using the user&#39;s browser settings as a benchmark for font size.
			This makes our digital presence accessible for users who may have different font-size preferences.
			Using REM values are the preferred implementation for any fonts that are viewed on user devices.
			Pixel values should only be used in cases where it is not possible to implement REM values (such as in .png or .jpeg images).
			In places where pixel sizes must be used, the length of text should be at a minimum.
		</p>
		<div id="type-scale">
			<div className="row">
				<h1 className="col-6"> Heading One</h1>
				<p className="col text-detail">1.875rem / 30px </p>
				<p className="col text-detail">Oswald Medium</p>
			</div>
			<div className="row">
				<h2 className="col-6"> Heading Two</h2>
				<p className="col text-detail">1.875rem / 30px</p>
				<p className="col text-detail">Oswald Medium</p>
			</div>
			<div className="row">
				<h3 className="col-6"> Heading Three</h3>
				<p className="col text-detail">1.3125rem / 21px </p>
				<p className="col text-detail">Lato Black</p>
			</div>
			<div className="row">
				<h4 className="col-6"> Heading Four</h4>
				<p className="col text-detail">1.125rem / 18px</p>
				<p className="col text-detail">Lato Black </p>
			</div>
			<div className="row">
				<h5 className="col-6"> Heading Five</h5>
				<p className="col text-detail">1rem / 16px </p>
				<p className="col text-detail">Lato Black </p>
			</div>
			<div className="row">
				<h5 className="col-6"> Heading Six</h5>
				<p className="col text-detail">0.8rem / 16px / 24px</p>
				<p className="col text-detail">Lato Black</p>
			</div>
			<div className="row">
				<p className="col-6">Body</p>
				<p className="col text-detail">0.875rem / 14px </p>
				<p className="col text-detail">Lato Regular</p>
			</div>
			<div className="row">
				<p className="col-6">
					<b> Body Bold</b>
				</p>
				<p className="col text-detail">0.875rem / 14px</p>
				<p className="col text-detail">Lato Black </p>
			</div>
			<div className="row">
				<h6 className="col-6"> Button & Meta</h6>
				<p className="col text-detail">0.875rem / 14px</p>
				<p className="col text-detail uppercase" >Lato Black Uppercase</p>
			</div>


			<div
				className="accordion md-accordion"
				id="accordionEx"
				role="tablist"
				aria-multiselectable="true"
			>
				<div className="card">
					<div className="card-header" role="tab" id="headingOne1">
						<a
							data-toggle="collapse"
							data-parent="#accordionEx"
							href="#collapseOne1"
							aria-expanded="true"
							aria-controls="collapseOne1"
						>
							<p className="mb-0 bold">
								View CSS <i className="fas fa-plus rotate-icon"></i>
							</p>
						</a>
					</div>

					<div
						id="collapseOne1"
						className="collapse"
						role="tabpanel"
						aria-labelledby="headingOne1"
						data-parent="#accordionEx"
					>
						<div className="card-body">
							<div className="bd-clipboard"></div>

							<h6> CSS </h6>

							<PrismCode
								code={ButtonCodeCSS}
								language="css"
								plugins={["line-numbers"]}
							/>
						</div>
					</div>
				</div>
			</div>
			<h3>Paragraphs</h3>
			<p>
				{" "}
				The default body text size is 1rem which is the size a user has configured in their webbrowser. 16px is the default for current web browsers,
				and for mediums where pixel height must be used for font sizing.
				For optimum reading length, lines should be between 60-80 characters long. {" "}
			</p>

			<h1> Main Section Title </h1>
			<h3>Sub-section Title</h3>
			<p>
				{" "}
				Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
				tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
				veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
				commodo consequat. Duis aute irure dolor in reprehenderit in voluptate
				velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
				occaecat cupidatat non proident, sunt in culpa qui officia deserunt
				mollit anim.
			</p>
			<h4>Inner Section Title</h4>
			<p>
				Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
				tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
				veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
				commodo consequat. Duis aute irure dolor in reprehenderit in voluptate
				velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
				occaecat cupidatat non proident, sunt in culpa qui officia deserunt
				mollit anim.
			</p>
			<h5> Deep Section Title </h5>
			<p>
				{" "}
				Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
				tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
				veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
				commodo consequat. Duis aute irure dolor in reprehenderit in voluptate
				velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
				occaecat cupidatat non proident, sunt in culpa qui officia deserunt
				mollit anim.
			</p>
		</div>
	</div>
);

export default TypographyPage;
