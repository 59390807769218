import React from "react";
import { Link, NavLink } from "react-router-dom";
import LATICRETELogo from "../../images/LATICRETE Logo_4C.png";

const Header = () => {
	const activeStyle = { color: "#00ACD4" };
	return (
		<div>
			<nav className="main-desktop">
				<ul>
					<li>
						<Link to={"/"}>
							<a className="navbar-brand" href="/">
								{" "}
								<img
									style={{ width: "120px" }}
									src={LATICRETELogo}
									alt="LATICRETE logos with different backgrounds"
								/>{" "}
							</a>
						</Link>
					</li>
					<br />
					<b>FOUNDATIONS</b>

					<li>
						<NavLink to="/logo" activeStyle={activeStyle}>
							Logo
						</NavLink>
					</li>
					<li>
						<NavLink to="/color" activeStyle={activeStyle}>
							Colors
						</NavLink>
					</li>
					<li>
						<NavLink to="/typography" activeStyle={activeStyle}>
							Typography
						</NavLink>
					</li>
					<li>
						<NavLink to="/element-pos" activeStyle={activeStyle}>
							Element Positioning
						</NavLink>
					</li>
					<p></p>

					<b>COMPONENTS</b>

					<li>
						<NavLink to="/button" activeStyle={activeStyle}>
							Buttons
						</NavLink>
					</li>
					<li>
						<NavLink to="/dialog" activeStyle={activeStyle}>
							Dialogs
						</NavLink>
					</li>
					<li>
						<NavLink to="/feedback" activeStyle={activeStyle}>
							Feedback
						</NavLink>
					</li>
					<li>
						<NavLink to="/form-element" activeStyle={activeStyle}>
							Form Elements
						</NavLink>
					</li>
					<li>
						<NavLink to="/list" activeStyle={activeStyle}>
							Lists
						</NavLink>
					</li>
					<li>
						<NavLink to="/collapsible" activeStyle={activeStyle}>
							Collapsibles
						</NavLink>
					</li>
					<li>
						<NavLink to="/table" activeStyle={activeStyle}>
							Tables
						</NavLink>
					</li>
					<li>
						<NavLink to="/image" activeStyle={activeStyle}>
							Images
						</NavLink>
					</li>
				</ul>
			</nav>

			<nav className="mobile navbar navbar-light light-blue lighten-4">
				<button
					className="mobile navbar-toggler toggler-example"
					type="button"
					data-toggle="collapse"
					data-target="#navbarSupportedContent1"
					aria-controls="navbarSupportedContent1"
					aria-expanded="false"
					aria-label="Toggle navigation"
				>
					<span className="dark-blue-text">
						<i className="fas fa-bars fa-2x"></i>
					</span>
				</button>
				<a className="navbar-brand mobile" href="/">
					<img
						style={{ float: "left", width: "120px" }}
						src={LATICRETELogo}
						alt="LATICRETE logos with different backgrounds"
					/>{" "}
				</a>

				<div className="collapse navbar-collapse" id="navbarSupportedContent1">
					<ul className="navbar-nav mr-auto mobile">
						<b>FOUNDATIONS</b>
						<li>
							<NavLink to="/logo" activeStyle={activeStyle}>
								Logo
							</NavLink>
						</li>
						<li>
							<NavLink to="/color" activeStyle={activeStyle}>
								Colors
							</NavLink>
						</li>
						<li>
							<NavLink to="/typography" activeStyle={activeStyle}>
								Typography
							</NavLink>
						</li>
						<li>
							<NavLink to="/element-pos" activeStyle={activeStyle}>
								Element Positioning
							</NavLink>
						</li>
						<p></p>

						<b>COMPONENTS</b>

						<li>
							<NavLink to="/button" activeStyle={activeStyle}>
								Buttons
							</NavLink>
						</li>
						<li>
							<NavLink to="/dialog" activeStyle={activeStyle}>
								Dialogs
							</NavLink>
						</li>
						<li>
							<NavLink to="/feedback" activeStyle={activeStyle}>
								Feedback
							</NavLink>
						</li>
						<li>
							<NavLink to="/form-element" activeStyle={activeStyle}>
								Form Elements
							</NavLink>
						</li>
						<li>
							<NavLink to="/list" activeStyle={activeStyle}>
								Lists
							</NavLink>
						</li>
						<li>
							<NavLink to="/collapsible" activeStyle={activeStyle}>
								Collapsibles
							</NavLink>
						</li>
						<li>
							<NavLink to="/table" activeStyle={activeStyle}>
								Tables
							</NavLink>
						</li>
						<li>
							<NavLink to="/image" activeStyle={activeStyle}>
								Images
							</NavLink>
						</li>
					</ul>
				</div>
			</nav>
		</div>
	);
};

export default Header;
