import React from "react";
import { PrismCode } from "../../common/PrismCode.js";

var exampleTxt = "EXAMPLES";

const ButtonCodeHTML = `<button className="btn-outline-primary"> Secondary </button>
<button className="btn-primary"> Primary Action </button>
<button className="btn-danger"> Caution </button>
`;
const ButtonCodeCSS = `button {
    box-shadow: none !important;
    background-color: #efeeea;
    border: 1px solid #efeeea;
    border-radius: 4px !important;
    text-transform: uppercase !important;
    padding: 0.7em 1.6em;
    margin-right: 1em !important;
    margin-bottom: 1em !important;
    font-size: 0.8em !important;
}

button:focus {
    outline: none;
    box-shadow: none;
}

button:hover {
    background-color: #c4c4c4 !important;
}

.btn-primary {
    background-color: #00acd4 !important;
    border-color: #00acd4 !important;
}

.btn-primary:hover {
    background-color: #00a4ca !important;
    border-color: #00a4ca !important;
    color: white;
}

.btn-outline-primary {
    color: #00acd4 !important;
    background-color: white !important;
    border-color: #00acd4 !important;
}

.btn-outline-primary:hover {
    background-color: #00acd4 !important;
    border-color: #00acd4 !important;
    color: white !important;
}

.btn-danger {
    background-color: #ff4747 !important;
    border-color: #ff4747 !important;
}`;

const ButtonCodeDisabledHTML = `<button className="btn-disabled"> Default </button>
<button className="btn-outline-primary btn-disabled"> Secondary </button>
<button className="btn-primary btn-disabled"> Primary Action </button>
<button className="btn-danger btn-disabled"> Caution </button>
`;
const ButtonCodeDisabledCSS = `.btn-disabled {
    background-color: white !important;
    color: #c4c4c4 !important;
    border-color: #c4c4c4 !important;
    pointer-events: none;
}`;

const PaginationCodeHTML = `<nav aria-label="Page navigation example">
	<ul className="pagination">
		<li className="page-item">
			<a
				className="page-link page-item-disabled"
				href="#" 
				aria-label="Previous">
				<span aria-hidden="true">&laquo;</span>
				<span className="sr-only"></span>
			</a>
		</li>
		<li className="page-item ">
			<a className="page-link active" href="#">
				1
			</a>
		</li>
		<li className="page-item">
			<a className="page-link" href="#">
				2
			</a>
		</li>
		<li className="page-item">
			<a className="page-link" href="#">
				3
			</a>
		</li>
		<li className="page-item">
			<a className="page-link" href="#">
				...
			</a>
		</li>
		<li className="page-item">
			<a className="page-link" href="#">
				10
			</a>
		</li>
		<li className="page-item">
			<a className="page-link page-item-next" href="#" aria-label="Next">
				<span aria-hidden="true">&raquo;</span>
				<span className="sr-only"></span>
			</a>
		</li>
	</ul>
</nav>`;
const PaginationCodeCSS = `.pagination a {
    margin: 0 8px;
    border: none;
    color: #00acd4;
}

.pagination a.active {
    border: solid 2px #c4c4c4;
    pointer-events: none;
    color: black;
}

.pagination a:hover {
    text-decoration: underline;
    color: black;
    background-color: white;
}

.pagination a.page-item-next {
    background-color: #00acd4;
    border: solid 2px #00acd4;
    color: white;
}

.pagination a.page-item-next:hover {
    background-color: white;
    border: solid 2px #00acd4;
    color: #00acd4;
    text-decoration: none;
}

.pagination a.page-item-disabled {
    background-color: #c4c4c4;
    border: solid 2px #c4c4c4;
    pointer-events: none;
    color: white;
}`;

const ComboButtonCodeHTML = `<div className="btn-group">
<button type="button" className="btn btn-primary btn-combo-main">
	Primary
</button>
<button
	type="button"
	className="btn btn-primary dropdown-toggle dropdown-toggle-split .btn-combo-arrow"
	data-bs-toggle="dropdown"
>
	<span className="visually-hidden">Toggle Dropdown</span>
</button>
<div className="dropdown-menu">
	<a href="#" className="dropdown-item">
		Action
	</a>
	<a href="#" className="dropdown-item">
		Another action
	</a>
	<div className="dropdown-divider"></div>
	<a href="#" className="dropdown-item">
		Separated link
	</a>
</div>
</div>`;

const ComboButtonCodeCSS = `.btn-combo-main {
    margin-right: 0px !important;
    border-radius: 4px 0px 0px 4px !important;
}

.btn-combo-arrow {
    margin-right: 0px !important;
    border-radius: 4px 0px 0px 4px !important;
}`;

const ButtonPage = () => (
	<div id="button-page">
		<h1> Buttons </h1>
		<h3>Priority</h3>
		<p>
			Buttons are categorized by importance and can be applied to a variety of
			use cases and calls-to-action.
		</p>
		<p>
			{" "}
			Implementation Notes: Import bootstrap.css and bootstrap.js as seen{" "}
			<a
				href="https://getbootstrap.com/docs/4.3/getting-started/introduction/"
				target="_blank"
				rel="noopener noreferrer"
			>
				here
			</a>
			.{" "}
		</p>

		<h5>{exampleTxt}</h5>
		<button> Default </button>
		<button className="btn-outline-primary"> Secondary </button>
		<button className="btn-primary"> Primary Action </button>
		<button className="btn-danger"> Caution </button>

		<div
			className="accordion md-accordion"
			id="accordionEx"
			role="tablist"
			aria-multiselectable="true"
		>
			<div className="card">
				<div className="card-header" role="tab" id="headingOne1">
					<a
						data-toggle="collapse"
						data-parent="#accordionEx"
						href="#collapseOne1"
						aria-expanded="true"
						aria-controls="collapseOne1"
					>
						<p className="mb-0 bold">
							View Source Code <i className="fas fa-plus rotate-icon"></i>
						</p>
					</a>
				</div>

				<div
					id="collapseOne1"
					className="collapse"
					role="tabpanel"
					aria-labelledby="headingOne1"
					data-parent="#accordionEx"
				>
					<div className="card-body">
						<h6> HTML </h6>
						<div className="bd-clipboard"></div>

						<PrismCode
							code={ButtonCodeHTML}
							language="html"
							plugins={["line-numbers"]}
						/>

						<h6> CSS </h6>

						<PrismCode
							code={ButtonCodeCSS}
							language="css"
							plugins={["line-numbers"]}
						/>
					</div>
				</div>
			</div>
		</div>
		<h3>Disabled</h3>
		<h5>{exampleTxt}</h5>
		<button className="btn-disabled"> Default </button>
		<button className="btn-outline-primary btn-disabled"> Secondary </button>
		<button className="btn-primary btn-disabled"> Primary Action </button>
		<button className="btn-danger btn-disabled"> Caution </button>

		<div
			className="accordion md-accordion"
			id="accordionEx"
			role="tablist"
			aria-multiselectable="true"
		>
			<div className="card">
				<div className="card-header" role="tab" id="headingOne1">
					<a
						data-toggle="collapse"
						data-parent="#accordionEx"
						href="#collapseOne2"
						aria-expanded="true"
						aria-controls="collapseOne2"
					>
						<p className="mb-0 bold">
							View Source Code <i className="fas fa-plus rotate-icon"></i>
						</p>
					</a>
				</div>

				<div
					id="collapseOne2"
					className="collapse"
					role="tabpanel"
					aria-labelledby="headingOne2"
					data-parent="#accordionEx"
				>
					<div className="card-body">
						<h6> HTML </h6>
						<div className="bd-clipboard"></div>
						<PrismCode
							code={ButtonCodeDisabledHTML}
							language="html"
							plugins={["line-numbers"]}
						/>

						<h6> CSS </h6>

						<PrismCode
							code={ButtonCodeDisabledCSS}
							language="css"
							plugins={["line-numbers"]}
						/>
					</div>
				</div>
			</div>
		</div>

		<h3>Pagination</h3>
		<h5>{exampleTxt}</h5>

		<nav aria-label="Page navigation example">
			<ul className="pagination">
				<li className="page-item">
					<a
						className="page-link page-item-disabled"
						href="#"
						aria-label="Previous"
					>
						<span aria-hidden="true">&laquo;</span>
						<span className="sr-only"></span>
					</a>
				</li>
				<li className="page-item ">
					<a className="page-link active" href="#">
						1
					</a>
				</li>
				<li className="page-item">
					<a className="page-link" href="#">
						2
					</a>
				</li>
				<li className="page-item">
					<a className="page-link" href="#">
						3
					</a>
				</li>
				<li className="page-item">
					<a className="page-link" href="#">
						...
					</a>
				</li>
				<li className="page-item">
					<a className="page-link" href="#">
						10
					</a>
				</li>
				<li className="page-item">
					<a className="page-link page-item-next" href="#" aria-label="Next">
						<span aria-hidden="true">&raquo;</span>
						<span className="sr-only"></span>
					</a>
				</li>
			</ul>
		</nav>

		<div
			className="accordion md-accordion"
			id="accordionEx"
			role="tablist"
			aria-multiselectable="true"
		>
			<div className="card">
				<div className="card-header" role="tab" id="headingOne1">
					<a
						data-toggle="collapse"
						data-parent="#accordionEx"
						href="#collapsePaginationCode"
						aria-expanded="true"
						aria-controls="collapsePaginationCode"
					>
						<p className="mb-0 bold">
							View Source Code <i className="fas fa-plus rotate-icon"></i>
						</p>
					</a>
				</div>

				<div
					id="collapsePaginationCode"
					className="collapse"
					role="tabpanel"
					aria-labelledby="headingOne3"
					data-parent="#accordionEx"
				>
					<div className="card-body">
						<h6> HTML </h6>
						<div className="bd-clipboard"></div>
						<PrismCode
							code={PaginationCodeHTML}
							language="html"
							plugins={["line-numbers"]}
						/>

						<h6> CSS </h6>

						<PrismCode
							code={PaginationCodeCSS}
							language="css"
							plugins={["line-numbers"]}
						/>
					</div>
				</div>
			</div>
		</div>

		<h3>Combo Buttons</h3>
		<h5>{exampleTxt}</h5>
		<div className="btn-group">
			<button type="button" className="btn btn-primary btn-combo-main">
				Primary
			</button>
			<button
				type="button"
				className="btn btn-primary dropdown-toggle dropdown-toggle-split .btn-combo-arrow"
				data-bs-toggle="dropdown"
			>
				<span className="visually-hidden">Toggle Dropdown</span>
			</button>
			<div className="dropdown-menu">
				<a href="#" className="dropdown-item">
					Action
				</a>
				<a href="#" className="dropdown-item">
					Another action
				</a>
				<div className="dropdown-divider"></div>
				<a href="#" className="dropdown-item">
					Separated link
				</a>
			</div>
		</div>

		<div
			className="accordion md-accordion"
			id="accordionEx"
			role="tablist"
			aria-multiselectable="true"
		>
			<div className="card">
				<div className="card-header" role="tab" id="headingOne1">
					<a
						data-toggle="collapse"
						data-parent="#accordionEx"
						href="#collapseComboButton"
						aria-expanded="true"
						aria-controls="collapseComboButton"
					>
						<p className="mb-0 bold">
							View Source Code <i className="fas fa-plus rotate-icon"></i>
						</p>
					</a>
				</div>

				<div
					id="collapseComboButton"
					className="collapse"
					role="tabpanel"
					aria-labelledby="headingOne2"
					data-parent="#accordionEx"
				>
					<div className="card-body">
						<h6> HTML </h6>
						<div className="bd-clipboard"></div>
						<PrismCode
							code={ComboButtonCodeHTML}
							language="html"
							plugins={["line-numbers"]}
						/>

						<h6> CSS </h6>

						<PrismCode
							code={ComboButtonCodeCSS}
							language="css"
							plugins={["line-numbers"]}
						/>
					</div>
				</div>
			</div>
		</div>
	</div>
);

export default ButtonPage;
