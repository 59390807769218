import React from "react";

const DialogPage = () => (
	<div>
		<h1>Dialogs (Modal Windows)</h1>
		<h2> Intro </h2>
		<p>
			Dialogs, also known as modal windows, are used for a variety of purposes
			throughout the digital experience. Use cases include forms, verification
			prompts, and viewing supplemental content within an abridged context.
		</p>
		<h5> EXAMPLES</h5>

		<button
			type="button"
			className="btn btn-primary btn-lg"
			data-toggle="modal"
			data-target="#myModal"
		>
			Open Modal
		</button>

		<div id="myModal" className="modal fade" role="dialog">
			<div className="modal-dialog">
				<div className="modal-content">
					<div className="modal-header">
						<button type="button" className="close" data-dismiss="modal">
							<i className="fas fa-times rotate-icon"></i>
						</button>
					</div>
					<div className="modal-body">
						<h4 className="modal-title">Are you sure you want to continue?</h4>{" "}
						<p>
							Completing this action will do nothing because this is an example.
						</p>
					</div>
					<div className="modal-footer">
						<button
							type="button"
							className="btn btn-default"
							data-dismiss="modal"
						>
							Close
						</button>
						<button
							type="button"
							className="btn btn-primary"
							data-dismiss="modal"
						>
							Submit
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
);

export default DialogPage;
