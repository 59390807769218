import React from "react";

const FormElementPage = () => (
	<div>
		<h1>Form Elements</h1>
		{/* Dropdowns */}
		<h3> Dropdowns </h3>
		<p>
			Form elements are used for online forms, web applcation CRUD and master-detail interfaces, 
			and for certain calls-to-action. They make use of various colors from our palette.
		</p>
		<h5>EXAMPLE</h5>
		<div className="dropdown">
			<button
				className="dropdown-toggle"
				type="button"
				id="dropdownMenuButton"
				data-toggle="dropdown"
				aria-haspopup="true"
				aria-expanded="false"
			>
				Default<i className="fas fa-angle-down"></i>
			</button>
			<div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
				<a className="dropdown-item" href="#">
					Action
				</a>
				<a className="dropdown-item" href="#">
					Another action
				</a>
				<a className="dropdown-item" href="#">
					Something else here
				</a>
			</div>

			<button
				className="dropdown-toggle dropdown-secondary"
				type="button"
				id="dropdownMenuButton"
				data-toggle="dropdown"
				aria-haspopup="true"
				aria-expanded="false"
			>
				Secondary<i className="fas fa-angle-down"></i>
			</button>
			<div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
				<a className="dropdown-item" href="#">
					Action
				</a>
				<a className="dropdown-item" href="#">
					Another action
				</a>
				<a className="dropdown-item" href="#">
					Something else here
				</a>
			</div>
		</div>
		{/* Text Input */}
		<h3> Text Input </h3>
		<h5>EXAMPLE</h5>
		<div className="col-md-4 mb-3">
			<p className="form-description">First Name</p>
			<p className="form-description form-description-required">Required</p>
			<div className="input-group mb-3">
				<div className="input-group-prepend"></div>
				<input
					type="text"
					className="form-control"
					placeholder="Jane"
					aria-label="Jane"
					aria-describedby="basic-addon1"
				/>
			</div>
		</div>
		<h3> Text input with prefix symbol attached </h3>
		<h5>EXAMPLE</h5>
		<div className="col-md-4 mb-3">
			<p className="form-description ">Total Budget</p>
			<div className="input-group mb-3">
				<div className="input-group-prepend">
					<span className="input-group-text">$</span>
				</div>
				<input
					type="text"
					className="form-control"
					placeholder="Enter Amount"
					aria-label="Dollar Amount"
					aria-describedby="basic-addon1"
				/>
			</div>
		</div>
		<h3> Text input with suffix symbol attached </h3>
		<h5>EXAMPLE</h5>
		<div className="col-md-4 mb-3">
			<p className="form-description ">Coverage</p>
			<div className="input-group mb-3">
				<input
					type="text"
					className="form-control"
					placeholder="Enter Amount"
					aria-label="Dollar Amount"
					aria-describedby="basic-addon1"
				/>
				<div className="input-group-append">
					<span className="input-group-text">%</span>
				</div>
			</div>
		</div>
		<h3> Text input with button </h3>
		<h5>EXAMPLE</h5>
		<div className="col-md-4 mb-3">
			<div className="input-group mb-3">
				<input
					type="text"
					className="form-control"
					placeholder="Email Address"
					aria-label="Email Address"
					aria-describedby="basic-addon2"
				/>

				<div className="input-group-append">
					<button className="btn btn-primary" type="button">
						Sign Up
					</button>
				</div>
			</div>
		</div>
		<h3> Validating Input </h3>
		<h5>EXAMPLE</h5>
		<form>
			<div className="form-row row">
				<div className="col-md-6">
					<p className="form-description">First Name</p>

					<div className="input-group-prepend"></div>
					<input
						type="text"
						className="form-control is-valid"
						aria-label="Jane"
						aria-describedby="basic-addon1"
						value="Jane"
					/>
					<div className="valid-feedback">Looks good!</div>
				</div>
				<div className="col-md-6">
					<p className="form-description">Last Name</p>

					<input
						type="text"
						className="form-control is-invalid"
						placeholder=""
						aria-label="Last Name"
						aria-describedby="basic-addon1"
					/>
					<div className="invalid-feedback">Last Name is Required</div>
				</div>
			</div>
		</form>
		<h3>Checkbox</h3>
		<h5>EXAMPLE</h5>

		<div className="form-check">
			<input
				className="form-check-input"
				type="checkbox"
				value=""
				id="defaultCheck1"
			/>
			<label className="form-check-label">Enabled</label>
		</div>
		<div className="form-check">
			<input
				className="form-check-input"
				type="checkbox"
				value=""
				id="defaultCheck1"
				disabled="disabled"
			/>
			<label className="form-check-label">Disabled</label>
		</div>
		<div className="form-check">
			<input
				className="form-check-input"
				type="checkbox"
				value=""
				id="defaultCheck1"
				disabled="disabled"
				checked="checked"
			/>
			<label className="form-check-label">Disabled and Checked</label>
		</div>
		<h3>Radio</h3>
		<h5>EXAMPLE</h5>

		<div className="form-check">
			<input
				input
				className="form-check-input"
				type="radio"
				name="flexRadioDefault"
				id="flexRadioDefault1"
			/>
			<label className="form-check-label">Enabled</label>
		</div>
		<div className="form-check">
			<input
				input
				className="form-check-input"
				type="radio"
				name="flexRadioDefault"
				id="flexRadioDefault1"
			/>
			<label className="form-check-label">Enabled</label>
		</div>
		<div className="form-check">
			<input
				input
				className="form-check-input"
				type="radio"
				name="flexRadioDefault2"
				id="flexRadioDefault1"
				disabled="disabled"
			/>

			<label className="form-check-label">Disabled</label>
		</div>
	</div>
);

export default FormElementPage;
