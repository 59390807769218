import React from "react";

const FeedbackPage = () => (
	<div>
		<h1>Feedback</h1>
		<h2>Intro </h2>
		<p>
			Feedback elements notify the user of important information, or prompt for
			an action by the user. Use cases include growl or validation messages displayed
			as a result of user input. Feedback elements use the feedback colors from our
			palette.
		</p>
		<div className="row colorRow">
			<div className="successGreenBg ">
				<i className="far fa-check-circle "></i>
				<p>
					<b> ACCOUNT STATUS</b>
					<br />
					All good!{" "}
				</p>
				<i className="fas fa-times rotate-icon"></i>
			</div>
		</div>

		<div className="row colorRow">
			<div className="cautionYellowBg">
				<i className="far fa-check-circle "></i>
				<p>
					<b> ACCOUNT STATUS</b>
					<br />
					You closed your account on Dec 25, 2020 at 8:00pm EST{" "}
				</p>
				<i className="fas fa-times rotate-icon"></i>
			</div>
		</div>
		<div className="row colorRow whiteTxt">
			<div className=" criticalRedBg ">
				<i className="far fa-check-circle "></i>
				<p>
					<b> ACCOUNT STATUS</b>
					<br />
					Something terrible happened.{" "}
					<a style={{ color: "white" }} href="">
						See if this fixes it.
					</a>{" "}
				</p>
				<i className="fas fa-times rotate-icon"></i>
			</div>
		</div>
		<div className="row colorRow">
			<div className=" infoBlueBg ">
				<i className="far fa-check-circle "></i>
				<p>
					<b> ACCOUNT STATUS</b>
					<br />
					You closed your account on Dec 25, 2020 at 8:00pm EST{" "}
				</p>
				<i className="fas fa-times rotate-icon"></i>
			</div>
		</div>
		<div className="row colorRow">
			<div className=" infoBlueBg ">
				<i className="far fa-check-circle "></i>
				<p>
					<b> FYI</b>
					<br />
					Feedback blocks can contain buttons{" "}
					<div style={{ float: "right" }} className="btn-group">
						<button type="button" className="btn btn-primary btn-combo-main">
							Primary
						</button>
						<button
							type="button"
							className="btn btn-primary dropdown-toggle dropdown-toggle-split .btn-combo-arrow"
							data-bs-toggle="dropdown"
						>
							<span className="visually-hidden">Toggle Dropdown</span>
						</button>
						<div className="dropdown-menu">
							<a href="#" className="dropdown-item">
								Action
							</a>
							<a href="#" className="dropdown-item">
								Another action
							</a>
							<div className="dropdown-divider"></div>
							<a href="#" className="dropdown-item">
								Separated link
							</a>
						</div>
					</div>
				</p>
				<i
					style={{ paddingTop: "40px" }}
					className="fas fa-times rotate-icon"
				></i>
			</div>
		</div>
	</div>
);

export default FeedbackPage;
