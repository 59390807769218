import React from "react";

const HomePage = () => (
	<div>
		<h1>LATICRETE<sup>&reg;</sup> Digital Brand Guidelines</h1>
		<h2> Introduction </h2>
		<p>
			The LATICRETE<sup>&reg;</sup> Digital Brand Guidelines serve as a comprehensive resource
			for our digital brand presence. It is meant to accompany and complement the
			LATICRETE Global Brand Identity manual. The guidelines highlight important design
			best-practices for our company, and are intended to enforce visual consistency 
			throughout our many websites, applications and marketing campaigns.{" "}
		</p>
		<p>
			Though this site uses the <a href="https://reactjs.org/" target="_blank" rel="noopener noreferrer">React</a> and <a href="https://getbootstrap.com/" target="_blank" rel="noopener noreferrer">Bootstrap 5</a> UI frameworks, it is important
			to note that these design considerations are applicable regardless of the 
			technology stack of the application to which it is being applied. 
			Given that our applications are all designed to be mobile-first, 
			some of the best practices eluded to in the guidelines will be easier
			to accomplish using modern mobile responsive frameworks.
			All design elements can be inspected through a web browser and
			can be reproduced using any modern framework. A future release will
			include source code snippets within the page for convenience.
		</p>
		<p>
			It is also important to note that depending on the technology used, certain
			styles may not precisely duplicated. Therefore, application of the guidelines
			can have some circumstantial flexibility, but should be replicated as closely
			as possible.
		</p>
		<p>
			For questions on usage or feedback, please contact: <a href="mailto:webmaster@laticrete.com">webmaster@laticrete.com</a>
		</p>
	</div>
);

export default HomePage;
