import React from "react";
import { render } from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";

import App from "./components/App";
import "./index.css";
import "./prism.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.js";
import "prismjs/plugins/keep-markup/prism-keep-markup.js";

render(
	<Router>
		<App />
	</Router>,
	document.getElementById("app")
);
