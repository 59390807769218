import React from "react";
import pageImage from "../../../images/screenshots/typographyScreenshot.png";

const ElementPosPage = () => (
	<div>
		<h1>Element Positioning</h1>
		<h2> Introduction </h2>
		<p>
			Positioning of elements, white space, margins, and padding help to support
			and further define branding in the digital space. These guidelines extend
			to all digital media.
		</p>
		<h3>Typography</h3>
		<p>
			Headings and paragraphs should have consistent vertical spacing between
			each instance of text. Using the guidelines for each type of element,
			vertical white space between each element should equal the sum of each
			element’s respective white space value. The pixel scale is used for simplicity,
			however is meant as a reference only due to variations in how sizing is 
			measured across various digital properties.
		</p>
		<h5> Examples </h5>
		<img src={pageImage} style={{ width: "100%" }}></img>
	</div>
);

export default ElementPosPage;
