import React from "react";

import LATICRETELogo from "../../../images/LATICRETE Logo_4C.png";
import LATICRETELogoKO from "../../../images/LATICRETE Logo_4C KO.png";
import LATICRETELogoBlack from "../../../images/LATICRETE Logo_BLACK.png";
import LATICRETELogoBlackKO from "../../../images/LATICRETE Logo_BLACK KO.png";
import LATICRETELogoGray from "../../../images/LATICRETE Logo_GREY.png";
import LATICRETELogoGrayKO from "../../../images/LATICRETE Logo_GREY KO.png";
import LATICRETELogoCheck from "../../../images/LATICRETE Logo_4C_check.png";
import LATICRETELogoX from "../../../images/LATICRETE Logo_4C_X.png";

const LogoPage = () => (
	<div>
		<h1>Logo</h1>
		<h2> Introduction </h2>
		<p>
			The LATICRETE<sup>&reg;</sup> logo represents LATICRETE as a brand. The
			logo helps establish brand identity in a consistant way both offline and
			online. The relationship of elements within the logo should never be
			altered, however, elements from the logo can be encorporated into designs.
		</p>
		<h3> Backgrounds Must Be Solid Colors</h3>
		<p>
			Backgrounds behind the logo must be a solid color. Images or patterns
			behind the logo are not acceptable.{" "}
		</p>
		<div className="row">
			<div
				className="col-6 logoBoxExample"
				style={{ backgroundColor: "#EFEEEA" }}
			>
				<div className="test">
					<img
						className="test"
						src={LATICRETELogoCheck}
						alt="LATICRETE logos with different backgrounds"
					/>
				</div>
			</div>
			<div className="col-6 logoBoxExample logoBoxExampleBg ">
				<img
					src={LATICRETELogoX}
					alt="LATICRETE logos with different backgrounds"
				/>
			</div>
		</div>

		<h3> Light Background Logo Usage</h3>
		<p>
			{" "}
			When using a light background, use the logo with the black border and
			black &reg;.
		</p>
		<p> File Name: LATICRETE_logo_2C.pdf </p>
		<div className="row">
			<div className="col-6 logoBoxExample">
				<img
					className="test"
					src={LATICRETELogo}
					alt="LATICRETE logos with different backgrounds"
				/>
			</div>
			<div
				className="col-6 logoBoxExample "
				style={{ backgroundColor: "#FAECCA" }}
			>
				<img
					src={LATICRETELogo}
					alt="LATICRETE logos with different backgrounds"
				/>
			</div>
		</div>

		<h3> Dark Background Logo Usage</h3>
		<p>
			{" "}
			When using a dark background, use the logo with a white border and white
			&reg;.
			<p>
				{" "}
				File Name: LATICRETE_logo_2C_<b>KO</b>.pdf{" "}
			</p>
		</p>

		<div className="row">
			<div
				className="col-6 logoBoxExample"
				style={{ backgroundColor: "black" }}
			>
				<img
					className="test"
					src={LATICRETELogoKO}
					alt="LATICRETE logos with different backgrounds"
				/>
			</div>

			<div
				className="col-6 logoBoxExample "
				style={{ backgroundColor: "#5C0DA2" }}
			>
				<img
					src={LATICRETELogoKO}
					alt="LATICRETE logos with different backgrounds"
				/>
			</div>
		</div>

		<h3> Logo Variations </h3>
		<p>
			The LATICRETE<sup>&reg;</sup> logo can be displayed in blue, black or
			gray. The logo itself, and not the logo with its accompanying &reg;,
			should always be the focal point of the area in which the logo is
			displayed. Especially in cases where a platform does not allow for
			granular image placement, the below logos can be used to achieve proper
			focal point as transparency surrounding the logo has been adjusted to
			compensate.
		</p>
		<span></span>
		<div className="row logo-variation-row">
			<div className="col-sm-3">
				<img
					className="test"
					src={LATICRETELogo}
					alt="LATICRETE logos with different backgrounds"
				/>
			</div>
			<div className="col-sm-4 col-6">
				<span></span>
				<p>
					<a href="https://cmsglobalstorage.blob.core.windows.net/static-assets/LATICRETE Logo_4C.png">
						Download PNG
					</a>
					<br />
					<a href="https://cmsglobalstorage.blob.core.windows.net/static-assets/LATICRETE Logo_4C.svg">
						Download SVG
					</a>
				</p>
			</div>
			<div className="col-sm-5 col-6">
				<span></span>
				<p>Default logo for use on light backgrounds.</p>
			</div>
		</div>

		<div className="row logo-variation-row">
			<div className="col-sm-3" style={{ backgroundColor: "black" }}>
				<img
					src={LATICRETELogoKO}
					alt="LATICRETE logos with different backgrounds"
				/>
			</div>
			<div className="col-sm-4 col-6">
				<span></span>
				<p>
					<a href="https://cmsglobalstorage.blob.core.windows.net/static-assets/LATICRETE Logo_4C KO.png ">
						Download PNG
					</a>
					<br />
					<a href="https://cmsglobalstorage.blob.core.windows.net/static-assets/LATICRETE Logo_4C KO.svg">
						Download SVG
					</a>
				</p>
			</div>
			<div className="col-6 col-sm-5">
				<span></span>
				<p>Default logo for use on dark backgrounds.</p>
			</div>
		</div>

		<div className="row logo-variation-row">
			<div className="col-sm-3">
				<img
					src={LATICRETELogoGray}
					alt="LATICRETE logos with different backgrounds"
				/>
			</div>
			<div className="col-sm-4 col-6">
				<span></span>
				<p>
					<a href="https://cmsglobalstorage.blob.core.windows.net/static-assets/LATICRETE Logo_GREY.png ">
						Download PNG
					</a>
					<br />
					<a href="https://cmsglobalstorage.blob.core.windows.net/static-assets/LATICRETE Logo_GREY.svg">
						Download SVG
					</a>
				</p>
			</div>
			<div className="col-sm-5 col-6">
				<span></span>
				<p>Grey logo for use on light backgrounds.</p>
			</div>
		</div>

		<div className="row logo-variation-row">
			<div className="col-sm-3" style={{ backgroundColor: "black" }}>
				<img src={LATICRETELogoGrayKO} alt="LATICRETE gray logo" />
			</div>
			<div className="col-sm-4 col-6">
				<span></span>
				<p>
					<a href="https://cmsglobalstorage.blob.core.windows.net/static-assets/LATICRETE Logo_GREY KO.png ">
						Download PNG
					</a>

					<br />
					<a href="https://cmsglobalstorage.blob.core.windows.net/static-assets/LATICRETE Logo_GREY KO.svg">
						Download SVG
					</a>
				</p>
			</div>
			<div className="col-sm-5 col-6">
				<span></span>
				<p>Grey logo for use on dark backgrounds.</p>
			</div>
		</div>
		<div className="row logo-variation-row">
			<div className="col-sm-3">
				<img src={LATICRETELogoBlack} alt="LATICRETE gray logo" />
			</div>
			<div className="col-sm-4 col-6">
				<span></span>
				<p>
					<a href="https://cmsglobalstorage.blob.core.windows.net/static-assets/LATICRETE Logo_BLACK.png ">
						Download PNG
					</a>

					<br />
					<a href="https://cmsglobalstorage.blob.core.windows.net/static-assets/LATICRETE Logo_BLACK.svg">
						Download SVG
					</a>
				</p>
			</div>
			<div className="col-sm-5 col-6">
				<span></span>
				<p>Black logo for use on light backgrounds.</p>
			</div>
		</div>
		<div className="row logo-variation-row">
			<div className="col-sm-3" style={{ backgroundColor: "black" }}>
				<img src={LATICRETELogoBlackKO} alt="LATICRETE gray logo" />
			</div>
			<div className="col-sm-4  col-6">
				<span></span>
				<p>
					<a href="https://cmsglobalstorage.blob.core.windows.net/static-assets/LATICRETE Logo_BLACK KO.png ">
						Download PNG
					</a>
					<br />
					<a href="https://cmsglobalstorage.blob.core.windows.net/static-assets/LATICRETE Logo_BLACK KO.svg">
						Download SVG
					</a>
				</p>
			</div>
			<div className="col-sm-4 col-6 ">
				<span></span>
				<p>Black logo for use on dark backgrounds.</p>
			</div>
		</div>

		{/* 
		PNG: 
		https://cmsglobalstorage.blob.core.windows.net/static-assets/LATICRETE Logo_4C KO.png 
		https://cmsglobalstorage.blob.core.windows.net/static-assets/LATICRETE Logo_4C.png
		https://cmsglobalstorage.blob.core.windows.net/static-assets/LATICRETE Logo_BLACK KO.png
		https://cmsglobalstorage.blob.core.windows.net/static-assets/LATICRETE Logo_BLACK.png
		https://cmsglobalstorage.blob.core.windows.net/static-assets/LATICRETE Logo_GREY KO.png
		https://cmsglobalstorage.blob.core.windows.net/static-assets/LATICRETE Logo_GREY.png

		SVG:
		https://cmsglobalstorage.blob.core.windows.net/static-assets/LATICRETE Logo_4C.svg
		https://cmsglobalstorage.blob.core.windows.net/static-assets/LATICRETE Logo_4C KO.svg
		https://cmsglobalstorage.blob.core.windows.net/static-assets/LATICRETE Logo_BLACK KO.svg
		https://cmsglobalstorage.blob.core.windows.net/static-assets/LATICRETE Logo_BLACK.svg
		https://cmsglobalstorage.blob.core.windows.net/static-assets/LATICRETE Logo_GREY KO.svg
		https://cmsglobalstorage.blob.core.windows.net/static-assets/LATICRETE Logo_GREY.svg


		*/}
	</div>
);

export default LogoPage;
