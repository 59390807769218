import React from "react";

const ImagePage = () => (
	<div>
		<h1>Images</h1>
		<h2> Overview and rationale for “double-sized” images</h2>
		<p>
			Image sizes are based on how they need to render on all screen types,
			including high-density displays such as Apple Retina displays (Macbook,
			iPhone, iPad). To account for high-density displays, and to prevent
			pixelation at normal zoom on these devices, we create our assets at double the 
			dimensions as rendered on the screen. Since we are using a responsive
			front-end framework in most cases (Bootstrap 5), we must consider each use case for an
			image in regards to how it may vary in size across all devices. In all
			instances, we should use the largest rendered use case when compared
			across devices. On desktop view, an image may be partnered with text on
			one side, whereas that same content may render stacked vertically, with
			text above or below the image, with the image going full width of the
			device screen (such as in hero banner use cases). For general illustrative imagery, the
			default width is 1474px (any height).
		</p>
		<h2>High-level rules</h2>
		<ul>
			<li>All images should be at 72ppi without exception</li>
			<li>
				All images requiring a transparent background should be PNG-24 format
			</li>
			<li>
				All images that do not require a transparent background should be JPG or
				SVG format
			</li>
			<li>
				Use the free compression service at <a href="https://tinypng.com/" target="_blank" rel="noopener noreferrer">tinypng.com</a>, or another photo editor 
				to compress JPG and	PNG files
			</li>
			<li>
				No images should be directly used from a camera or smartphone, due to
				inconsistent dimensions and lack of compression for web use
			</li>
		</ul>
	</div>
);

export default ImagePage;
