import React from "react";

const ListPage = () => (
	<div>
		<h1>Lists</h1>
		<h2>Intro</h2>
		<p>
			Lists are uniform in look and feel across all digital media.
		</p>
		<ul>
			<li>Lorem ipsum dolor sit amet, consectetuer adipiscing elit.</li>
			<li>Aliquam tincidunt mauris eu risus.</li>
		</ul>
		<ul className="bullets-circle">
			<li>Lorem ipsum dolor sit amet, consectetuer adipiscing elit.</li>
			<li>Aliquam tincidunt mauris eu risus.</li>
		</ul>
	</div>
);

export default ListPage;
