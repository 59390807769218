import React from "react";
import { Route, Switch } from "react-router-dom";
import TypographyPage from "./pages/typography/TypographyPage";
import LogoPage from "./pages/logo/LogoPage";
import ColorPage from "./pages/color/ColorPage";
import ElementPosPage from "./pages/element-pos/ElementPosPage";
import ButtonPage from "./pages/button/ButtonPage";
import Header from "./common/Header";
import PageNotFound from "./PageNotFound";
import DialogPage from "./pages/dialog/DialogPage";
import FeedbackPage from "./pages/feedback/FeedbackPage";
import FormElementsPage from "./pages/form-element/FormElementPage";
import List from "./pages/list/ListPage";
import Collapsible from "./pages/collapsible/CollapsiblePage";
import TablePage from "./pages/table/TablePage";
import ImagePage from "./pages/image/ImagePage";
import HomePage from "./pages/home/HomePage";

function App() {
	return (
		<div className="row">
			<div>
				<Header />
			</div>
			<div className="content">
				<Switch>
					<Route exact path="/" component={HomePage} />
					<Route path="/typography" component={TypographyPage} />
					<Route path="/logo" component={LogoPage} />
					<Route path="/color" component={ColorPage} />
					<Route path="/element-pos" component={ElementPosPage} />
					<Route path="/button" component={ButtonPage} />
					<Route path="/dialog" component={DialogPage} />
					<Route path="/feedback" component={FeedbackPage} />
					<Route path="/form-element" component={FormElementsPage} />
					<Route path="/list" component={List} />
					<Route path="/collapsible" component={Collapsible} />
					<Route path="/table" component={TablePage} />
					<Route path="/image" component={ImagePage} />
					<Route component={PageNotFound} />
				</Switch>
			</div>
		</div>
	);
}

export default App;
