import React from "react";

const ColorPage = () => (
	<div>
		<h1>Color</h1>
		<h3>Primary</h3>
		<p>
			Primary colors are used liberally throughout our applications, websites and
			marketing campaigns. The Aqua color is funcionally equivalent to our primary
			logo color, and is typically used for horiontal rules, layout elements, 
			menu elements, and active states for hyperlinks.
		</p>
		<div className="row colorRow whiteTxt">
			<div className="col-lg-1 aquaBg"></div>
			<div className="col-lg-10 aquaBg offset-lg-1">
				<p>
					{" "}
					Aqua <br /> #2dabd1
				</p>
			</div>
		</div>
		<h3>Secondary</h3>
		<p>
			Secondary colors complement our primary color scheme and are intended to be used
			less frequently compared to our primary color, but are still frequently used as call-to-actions and
			backgrounds for UI components.
		</p>
		
		<div className="row colorRow whiteTxt">
			<div className="col-lg-1 darkBlueBg"></div>
			<div className="col-lg-10 darkBlueBg offset-lg-1">
				<p>
					{" "}
					Dark Blue <br /> #0083a1 
				</p>
			</div>
		</div>

		<div className="row colorRow">
			<div className="col-lg-1 lightBlueBg"></div>
			<div className="col-lg-10 lightBlueBg offset-lg-1">
				<p>
					{" "}
					Light Blue <br /> #b3e1f1
				</p>
			</div>
		</div>
		<div className="row colorRow">
			<div className="col-lg-1 xlightBlueBg"></div>
			<div className="col-lg-10 xlightBlueBg offset-lg-1">
				<p>
					{" "}
					Lightest Blue <br /> #f1f9fb
				</p>
			</div>
		</div>
		
		<h3> Neutral Colors </h3>
		<p>
			Neutral colors are used for table formatting, horizontal rules, shading, and design elements
			which do not require use of vibrant color, or where such colors would become
			visually overwhelming. Hazy is used frequently as a background color to break up whitespace on the page.

		</p>
		<div className="row colorRow whiteTxt">
			<div className="col-lg-1 blackBg"></div>
			<div className="col-lg-10 blackBg offset-lg-1">
				<p>
					{" "}
					Black <br /> #000000
				</p>
			</div>
		</div>

		<div className="row colorRow">
			<div className="col-lg-1 hazyBg"></div>
			<div className="col-lg-10 hazyBg offset-lg-1">
				<p>
					{" "}
					Hazy <br /> #f3f4f4
				</p>
			</div>
		</div>
		<div className="row colorRow">
			<div
				className="col-lg-1 whiteBg"
				style={{
					borderStyle: "solid",
					borderColor: "#C4C4C4",
					borderWidth: "1px",
				}}
			></div>
			<div
				className="col-lg-10 whiteBg offset-lg-1"
				style={{
					borderStyle: "solid",
					borderColor: "#C4C4C4",
					borderWidth: "1px",
				}}
			>
				<p>
					{" "}
					White <br /> #FFF
				</p>
			</div>
		</div>
		<h3>Accent Colors</h3>
		<p>	In addition to the brand color scheme of aqua and black, designers may use accent colors judiciously. Accent colors can bring emphasis or differentiation in information
			and may be used in typography, charts or information bars. 
			On laticrete.com, two blue accent colors are currently used in small amounts. The Light Blue (Accent) is being used as a border color around UI components
			The Dark Blue (Accent) is being used a button color when the primary Aqua blue is in the background. The green color is a color option for icons on the website.
</p>
			<div className="row colorRow">
			<div className="col-lg-1 lightBlueBorderBg"></div>
			<div className="col-lg-10 lightBlueBorderBg offset-lg-1">
				<p>
					{" "}
					Light Blue (Accent) <br /> #dff0f4
				</p>
			</div>
		</div>
		
		<div className="row colorRow whiteTxt">
			<div className="col-lg-1 darkBlueAccentBg"></div>
			<div className="col-lg-10 darkBlueAccentBg offset-lg-1">
				<p>
					{" "}
					Dark Blue (Accent) <br /> #20829f
				</p>
			</div>
		</div>
		<div className="row colorRow whiteTxt">
			<div className="col-lg-1 greenBg"></div>
			<div className="col-lg-10 greenBg offset-lg-1">
				<p>
					{" "}
					Green <br /> #84bb05
				</p>
			</div>
		</div>
		
	</div>
);

export default ColorPage;
